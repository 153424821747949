export const PROJECT_RECIPE_START = 'project-recipe/start';
export const PROJECT_RECIPE_SUCCESS = 'project-recipe/success';
export const PROJECT_RECIPE_ERROR = 'project-recipe/error';
export const PROJECT_RECIPE_RESET = 'project-recipe/reset';
export const RECIPE_VARIANT_ADD_ROW = 'recipe-variant/add-row';
export const RECIPE_VARIANT_ADD = 'recipe-variant/variant-add';
export const RECIPE_VARIANT_EDIT_PERCENTAGE = 'recipe-variant/edit-percentage';
export const RECIPE_VARIANT_EDIT_MATERIAL = 'recipe-variant/edit-material';
export const RECIPE_VARIANTS_SAVE_SUCCESS = 'recipe-variant/save-success';
export const RECIPE_VARIANT_MARK_FINAL = 'recipe-variant/mark-final';
export const RECIPE_COMPLETE = 'recipe-variant/complete';
export const PROJECT_RECIPE_FILTERED_VARIANTS_SUCCESS =
  'project-recipe/save-filtered-variants-success';
export const PROJECT_RECIPE_FILTER_VARIANT = 'project-recipe/filter-variant';
export const PROJECT_RECIPE_FILTERED_VARIANTS_RESULTS_SUCCESS =
  'project-recipe/filtered-variants-results';
