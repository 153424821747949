import {
  DOCUMENT_TABLE_ADD_ROW,
  DOCUMENT_TABLE_REMOVE_ROW,
  PROJECT_DATA_COMMENTS,
  PROJECT_DATA_DOCUMENT_COMMENTS,
  PROJECT_DATA_RESET_STATE,
  PROJECTDATA_CLEAR_SELECTED,
  PROJECTDATA_DESELECTALLRESULTS,
  PROJECTDATA_DESELECTRESULT,
  PROJECTDATA_GET_DOCUMENTS,
  PROJECTDATA_GET_RESULTS,
  PROJECTDATA_GET_TEST_MATRIX,
  PROJECTDATA_GET_TESTS,
  PROJECTDATA_PROCESS_RESULTS,
  PROJECTDATA_SELECTALLRESULTS,
  PROJECTDATA_SELECTRESULT,
  PROJECTDATA_SET_ACTIVE_DOCUMENT,
  PROJECTDATA_SET_ACTIVE_RESULT,
  PROJECTDATA_START,
  PROJECTDATA_UPDATE_RESULTTABLE,
} from './ProjectDataTypes';

const INITIAL_STATE = {
  loading: true,
  error: false,
  results: [],
  resultTables: [],
  activeResultTable: {
    resultId: '',
    resultTableId: '',
  },
  treeViewStructure: {},
  selectedResults: [],
  activeResult: null,
  activeTable: null,
  activeDocument: {
    data: {},
    results: [],
    resultsRowMap: {},
    initialSpecs: [],
    allSpecs: [],
    comments: [],
    material: [],
  },
  testMatrix: null,
  tests: null,
  comments: [],
  documents: [],
  dbMaterial: null,
};

export default (state: any = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case PROJECTDATA_START:
      return { ...state, loading: true, error: null };
    case PROJECTDATA_GET_RESULTS:
      const results = [
        ...(action.payload || []).map((resultEntity) =>
          (resultEntity.resultTables || []).map((rTable) => rTable._id).flat(),
        ),
      ];
      return {
        ...state,
        loading: false,
        error: null,
        results: action.payload,
        resultTables: results.flat(),
      };

    case PROJECTDATA_GET_TEST_MATRIX:
      const { materials, testMatrix } = action.payload;

      for (const selectedMaterial of testMatrix.selectedMaterials) {
        for (const material of materials) {
          if (selectedMaterial._id === material._id) {
            selectedMaterial.specifications = material.specifications;
          }
        }
      }
      return {
        ...state,
        testMatrix: testMatrix,
        loading: false,
        error: null,
      };
    case PROJECTDATA_GET_TESTS:
      return {
        ...state,
        tests: action.payload,
        loading: false,
        error: null,
      };
    case PROJECTDATA_GET_DOCUMENTS:
      return {
        ...state,
        loading: false,
        error: null,
        documents: action.payload,
      };
    case PROJECTDATA_PROCESS_RESULTS:
      const treeViewStructure = {};
      state.results.map((result) => {
        const testTypeLabel = result.test.testType.type;
        const testSubTypeLabel = result.test.testSubType.type;
        //If the test type doesn't exist in the tree structure, then the subtype doesn't exist as well
        if (!treeViewStructure[testTypeLabel]) {
          treeViewStructure[testTypeLabel] = {
            ...treeViewStructure[testTypeLabel],
            [testSubTypeLabel]: [result],
          };
        } else if (
          treeViewStructure[testTypeLabel] &&
          !treeViewStructure[testTypeLabel][testSubTypeLabel]
        ) {
          treeViewStructure[testTypeLabel] = {
            ...treeViewStructure[testTypeLabel],
            [testSubTypeLabel]: [result],
          };
        } else {
          treeViewStructure[testTypeLabel][testSubTypeLabel] = [
            ...treeViewStructure[testTypeLabel][testSubTypeLabel],
            result,
          ];
        }
      });
      return {
        ...state,
        treeViewStructure,
      };
    case PROJECTDATA_SELECTALLRESULTS:
      return {
        ...state,
        selectedResults: [...state.resultTables],
      };

    case PROJECTDATA_DESELECTALLRESULTS:
      return { ...state, selectedResults: [] };
    case PROJECTDATA_SELECTRESULT:
      const isItemPresent = state.selectedResults.includes(action.payload);
      if (!isItemPresent) {
        return {
          ...state,
          selectedResults: [...state.selectedResults, action.payload],
        };
      }
      return state;
    case PROJECTDATA_DESELECTRESULT:
      return {
        ...state,
        selectedResults: state.selectedResults.filter(
          (result) => result !== action.payload,
        ),
      };
    case PROJECT_DATA_COMMENTS:
      return {
        ...state,
        comments: [action.payload],
      };

    case PROJECT_DATA_DOCUMENT_COMMENTS:
      return {
        ...state,
        activeDocument: {
          ...state.activeDocument,
          comments: [action.payload],
        },
      };

    case PROJECTDATA_UPDATE_RESULTTABLE:
      const { resultID, resultTable } = action.payload;
      const updatedResults = state.results.map((result) => {
        if (result._id === resultID) {
          result.resultTables = result.resultTables.map((rTable) => {
            if (rTable._id === resultTable._id) {
              rTable = resultTable;
            }
            return rTable;
          });
        }
        return result;
      });
      return {
        ...state,
        results: updatedResults,
      };
    case PROJECTDATA_SET_ACTIVE_RESULT:
      return {
        ...state,
        activeResultTable: action.payload,
      };

    case PROJECTDATA_SET_ACTIVE_DOCUMENT: {
      const resultsRowMap = {};
      (action.payload?.results || []).map((result) => {
        const generatedId = Date.now() + Math.floor(Math.random() * 1000);
        resultsRowMap[generatedId] = {
          ...result,
          generatedId,
        };
      });

      return {
        ...state,
        activeDocument: { ...action.payload, resultsRowMap: resultsRowMap },
      };
    }

    case PROJECTDATA_CLEAR_SELECTED:
      return {
        ...state,
        selectedResults: [],
      };
    case PROJECT_DATA_RESET_STATE:
      return {
        ...INITIAL_STATE,
        selectedResults: state.selectedResults,
      };

    case DOCUMENT_TABLE_ADD_ROW:
      return {
        ...state,
        activeDocument: {
          ...state.activeDocument,
          results: [...state.activeDocument.results, action.payload],
          resultsRowMap: {
            ...state.activeDocument.resultsRowMap,
            [action.payload.generatedId]: action.payload,
          },
        },
      };

    case DOCUMENT_TABLE_REMOVE_ROW:
      const resultsRowMapEdited = { ...state.activeDocument.resultsRowMap };
      // delete specificationRowMapEdited[action.payload];
      resultsRowMapEdited[action.payload].deleted = true;
      return {
        ...state,
        activeDocument: {
          ...state.activeDocument,
          results: (state?.activeDocument?.results || []).map((result) =>
            result.generatedId !== action.payload
              ? result
              : { ...result, deleted: true },
          ),
          resultsRowMap: resultsRowMapEdited,
        },
      };
    default:
      return state;
  }
};
