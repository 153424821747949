import moment from 'moment';
import * as flatten from 'flat';
import { get as getPath, result } from 'lodash';
import * as marked from 'marked';
import { MATHEMATICAL_OPERATIONS } from '../containers/TestResults/components/AddTableColumn/AddTableColumn';
import { MESSAGE_ROLE } from 'containers/ChatDialog/components/Messages/Messages';

const getUniqueElementsByField = (arr: any[], label) => {
  const hashMap = {};
  arr.map((item) => {
    if (item[label]) {
      hashMap[item[label]] = item;
    }
  });
  return Object.values(hashMap) || [];
};

const transformDevices = (devices) => {
  const config = JSON.parse(localStorage.getItem('config') as any);

  if (devices.length) {
    return devices.map((device) => {
      const testType = config?.testTypes?.find(
        (type) => type._id === device.testType,
      );
      const testSubTypes = config?.testSubTypes?.filter((subtype) =>
        device.testSubTypes.includes(subtype._id),
      );

      return {
        ...device,
        companyDisplay: device?.company?.name,
        testType: testType?.label,
        testSubTypesDisplay: testSubTypes?.map((item) => item.label).join(', '),
      };
    });
  }
};

const transformRawDataUrl = (url) => {
  if (url.startsWith('/')) {
    url = url.substr(1);
  }

  if (url.endsWith('/')) {
    url = url.substr(0, url.length - 1);
  }

  if (url.includes('#')) {
    url = url.replaceAll('#', '%23');
  }

  return url;
};

const dateTimeHelper = (dateTime, type) => {
  if (type.includes('datepicker')) {
    return dateTime ? moment(dateTime).format('DD/MM/yyyy') : null;
  } else if (type.includes('timepicker')) {
    return dateTime ? moment(dateTime).format('HH:mm') : null;
  } else {
    return dateTime;
  }
};

const findPretreatmentNameForTest = (test, pretreatments) => {
  if (test && pretreatments) {
    const [pretreatmentId] = test.skzAutoclav;
    const pretreatment = pretreatments.find(
      (item) => item._id === pretreatmentId,
    );
    return pretreatment?.testName || '';
  } else return '-';
};

const transformHeadersForResultsTable = (headers) => {
  return headers
    ?.map((header) => {
      if (!!header.field) {
        return {
          Header: header.label,
          accessor: (rows) => rows[header.field],
        };
      }
    })
    .filter((item) => !!item);
};

const transformHeadersForChartTable = (parsedKeys, hiddenRows) => {
  return parsedKeys
    .filter((item) => {
      return !hiddenRows.includes(item);
    })
    .map((key, index) => ({
      value: key,
      type: 'line',
      id: `${key}-${index}`,
    }));
};

const transformHeadersForMetadataTable = (headers) => {
  return headers?.map((header) => ({
    Header: header,
    accessor: header,
  }));
};

const transformHeadersForManualInputsTable = (headers) => {
  return headers.map((header) => ({
    Header: header.label,
    accessor: header.name,
    id: header._id,
  }));
};

const transformDataForManualInputsTable = (data) => [
  data.reduce((obj, item) => ((obj[item.name] = item.value), obj), {}),
];

const transformDataForChart = (rows) => {
  const dataRows = [] as any;
  const keySet = new Set<string>([]);

  rows.forEach((row, index) => {
    const entries = Object.entries(row);
    const data = {
      name: row.time
        ? transformChartDates(row.time)
        : `Measurement ${index + 1}`,
    };

    entries.forEach(([key, value]) => {
      // If key has date or time just return as is
      if (
        key.toLowerCase().includes('date') ||
        key.toLowerCase().includes('time')
      ) {
        data[key] = value;
        return;
      }

      switch (typeof value) {
        case 'string':
          if (
            !!value
              .trim()
              .replace(',', '.')
              .match(/^-?(0|[1-9]\d*)(\.\d+)?$/)
          ) {
            // If string is a number (int or float)
            keySet.add(key);
            data[key] = parseFloat(value.trim().replace(',', '.'));
          } else if (
            !!value
              .trim()
              .replace(',', '.')
              .match(/[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/)
          ) {
            // If string is an exponential number
            keySet.add(key);
            data[key] = new Number(value.trim().replace(',', '.')).toFixed(8);
          }
          break;
        case 'number':
          if (!isNaN(value)) {
            // If number is not NaN
            keySet.add(key);
            data[key] = value;
          }
          break;
      }
    });
    dataRows.push(data);
  });

  return {
    data: dataRows,
    keys: Array.from(keySet as any),
  };
};

const transformDataForChartWithConfig = (rows, config) => {
  const dataRows = [] as any;
  const keySet = new Set<string>([]);

  rows.forEach((row, index) => {
    const entries = Object.entries(row);
    const data = {};

    entries.forEach(([key, value]) => {
      // If key has date or time just return as is
      if (
        key.toLowerCase().includes('date') ||
        key.toLowerCase().includes('time')
      ) {
        data[key] = value;
        return;
      }

      switch (typeof value) {
        case 'string':
          if (
            !!value
              .trim()
              .replace(',', '.')
              .match(/^-?(0|[1-9]\d*)(\.\d+)?$/)
          ) {
            // If string is a number (int or float)
            keySet.add(key);
            data[key] = parseFloat(value.trim().replace(',', '.'));
          } else if (
            !!value
              .trim()
              .replace(',', '.')
              .match(/[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/)
          ) {
            // If string is an exponential number
            keySet.add(key);
            data[key] = new Number(value.trim().replace(',', '.')).toFixed(8);
          }
          break;
        case 'number':
          if (!isNaN(value)) {
            // If number is not NaN
            keySet.add(key);
            data[key] = value;
          }
          break;
      }
    });
    dataRows.push(data);
  });

  const chartData = {
    data: dataRows,
    yMax: Math.floor(Math.max(...dataRows.map((value) => value[config.y]))),
    keys: Array.from(keySet as any),
  };

  return chartData;
};

const getAllHeaders = (headers) =>
  headers?.length && headers.map((header) => header.Header);

const transformColumnsForDataSearch = (
  data,
  query,
  fields,
  computedColumns,
  translate = (key) => 'Missing translation',
) => {
  const flattenQuery = flatten(query);
  const accumulatedResultFields = data?.reduce(
    (acc, dataEntry) =>
      dataEntry.resultTables?.resultTableRows?._id
        ? acc.concat(
            Object.keys(dataEntry.resultTables?.resultTableRows?.results),
          )
        : acc.concat(
            dataEntry.resultTables?.manualInputs?.map((input) => input.name),
          ),
    [],
  );
  const resultFields = Array.from(
    new Set([...Object.keys(flattenQuery), ...accumulatedResultFields]),
  );

  const columns = [] as {
    Header: string;
    accessor: any;
    id?: string;
    Cell?: any;
    subType?: string;
    accessField?: any;
    operations?: any;
    computational?: boolean;
    visible?: boolean;
  }[];

  resultFields.forEach((field, index) => {
    const foundField = fields.find((queryField) => {
      if (field) {
        field = field.replace(/\.value(\.from)*$/, '');
      }

      return queryField.fieldName?.includes(field);
    });
    if (foundField) {
      columns.push({
        Header: foundField.translated
          ? translate(foundField.label)
          : foundField.label,
        accessor: (row) => {
          const resultString = 'resultTables.resultTableRows.results';

          if (foundField.fieldName.includes(resultString)) {
            let fieldName = foundField.fieldName.replace(
              resultString + '.',
              '',
            );
            return getPath(row, `${resultString}['${fieldName}']`);
          }
          const value = getPath(
            row,
            foundField.fieldName.includes('test')
              ? foundField.fieldName.replace('label', 'type')
              : foundField.fieldName,
          );

          if (foundField.fieldName.includes('testType')) {
            return translate(`project:testType_${value}`);
          } else if (foundField.fieldName.includes('testSubType')) {
            return translate(`project:testSubType_${value}`);
          } else return value;
        },
        accessField: foundField.fieldName,
        id: foundField.label + index,
        Cell: (props) => {
          return foundField.type.includes('datepicker') && props.value
            ? new Intl.DateTimeFormat('en-GB').format(new Date(props.value))
            : props.value === 0 || props.value
            ? props.value
            : '';
        },
        subType: foundField.subType,
      });
    }
  });
  if (computedColumns.length) {
    computedColumns.map((computedColumn) => {
      const key =
        computedColumn.operant1 +
        getSignFromOperation(computedColumn.operation) +
        computedColumn.operant2;
      columns.push({
        Header: key,
        accessor: (row) => {
          return getPath(row, key);
        },
        computational: true,
        subType: SEARCH_TYPES.FLOAT,
        id: key,
        visible: computedColumn.visible,
      });
    });
  }
  return columns.length > 1 ? getUniqueElementsByField(columns, 'Header') : [];
};

const transformManualInputsForDataSearch = (data) => {
  let dataCopy = [...data];
  return (dataCopy || []).map((resultEntry) => {
    let output = { ...resultEntry };
    if (resultEntry.resultTables?.manualInputs?.length) {
      const formattedManualInputs = {};
      resultEntry.resultTables?.manualInputs?.forEach((manualInput) => {
        formattedManualInputs[manualInput.name] = manualInput.value;
      });
      output = {
        ...output,
        resultTables: {
          ...output.resultTables,
          manualInputs: formattedManualInputs,
        },
      };
    }
    return output;
  });
};

const getSignFromOperation = (operation) => {
  switch (operation) {
    case MATHEMATICAL_OPERATIONS.MULTIPLY:
      return ' * ';
    case MATHEMATICAL_OPERATIONS.DIVIDE:
      return ' / ';
    case MATHEMATICAL_OPERATIONS.SUBTRACT:
      return ' - ';
    case MATHEMATICAL_OPERATIONS.ADD:
    default:
      return ' + ';
  }
};

const transformFormattedDataWithComputedColumns = (
  formattedData,
  tableColumns,
  computedColumns: any[] = [],
) => {
  const cDataCopy = [...formattedData];
  cDataCopy.map((row) => {
    computedColumns.map((computedColumn) => {
      let { operant1, operant2, operant1numeric, operant2numeric, operation } =
        computedColumn;
      const key = operant1 + getSignFromOperation(operation) + operant2;
      const config = JSON.parse(localStorage.getItem('config') as any);
      const precision = config?.decimalPlaces || 3;

      if (!operant1numeric) {
        const operantAccessor: any = tableColumns.find(
          (column: any): any => column.Header === operant1,
        );
        if (operantAccessor) {
          operant1 =
            operantAccessor.accessor(row) ||
            getPath(row, computedColumn.Header);
        }
      }
      if (!operant2numeric) {
        const operantAccessor: any = tableColumns.find(
          (column: any): any => column.Header === operant2,
        );
        if (operantAccessor) {
          operant2 =
            operantAccessor.accessor(row) ||
            getPath(row, computedColumn.Header);
        }
      }
      switch (operation) {
        case MATHEMATICAL_OPERATIONS.MULTIPLY:
          row[key] = (parseFloat(operant1) * parseFloat(operant2)).toFixed(
            precision,
          );
          return;
        case MATHEMATICAL_OPERATIONS.DIVIDE:
          row[key] = (parseFloat(operant1) / parseFloat(operant2)).toFixed(
            precision,
          );
          return;
        case MATHEMATICAL_OPERATIONS.SUBTRACT:
          row[key] = (parseFloat(operant1) - parseFloat(operant2)).toFixed(
            precision,
          );
          return;
        case MATHEMATICAL_OPERATIONS.ADD:
        default:
          row[key] = (parseFloat(operant1) + parseFloat(operant2)).toFixed(
            precision,
          );
          return;
      }
    });
  });
  return cDataCopy;
};

const transformOverviewDates = (date) => {
  return date
    ? `${new Intl.DateTimeFormat('en-GB').format(new Date(date))}`
    : null;
};

const transformChartDates = (date) => {
  return date ? `${moment.utc(new Date(date)).format('DD/MM/YY HH:mm')}` : null;
};

export enum SEARCH_TYPES {
  TIME = 'Time',
  DATE = 'Date',
  DATETIME = 'DateTime',
  FLOAT = 'Float',
  INT = 'Int',
  STRING = 'String',
}

const filterNumericColumns = (tableColumns, hiddenColumns, useHeader) => {
  return tableColumns
    ?.filter(
      (column: any) =>
        [SEARCH_TYPES.FLOAT, SEARCH_TYPES.INT].includes(column.subType) &&
        !hiddenColumns.includes(column.id),
    )
    .map((column: any) => ({
      label: column.Header,
      value: useHeader ? column.Header : column.accessField,
    }));
};

const filterColumns = (tableColumns, hiddenColumns) => {
  return tableColumns
    ?.filter((column: any) => !hiddenColumns.includes(column.id))
    .map((column: any) => ({
      label: column.Header,
      value: column.accessField || column.Header,
    }));
};

const transformVisibleTableFields = (fields, filter) => {
  return fields
    ?.filter((field) => field[filter])
    ?.map((field) => ({
      type: 'label',
      label: field.label,
      field: field.pathAccessor || field.fieldName,
      sortable: field.sortable,
    }));
};

const transformRecipeVariantPercentageValue = (data) => {
  return data.rows.map((row) => {
    row.percentage = parseFloat(row?.percentage || 0).toFixed(2);
  });
};
const calculateRecipeVariantPercentage = (rows: []) => {
  const calculatedPercentage = rows.reduce(
    (accumulator: number, currentValue: any) =>
      accumulator + parseFloat(currentValue?.percentage),
    0,
  );
  return Number(calculatedPercentage.toFixed(2));
};

const transformChatMessage = (message) => {
  const tempMessage = { ...message };
  if (message.ToolRequest || message.ToolResponse) {
    tempMessage.toolType = message.ToolRequest
      ? 'Tool Request'
      : 'Tool Response';

    const toolData = message.ToolRequest
      ? message.ToolRequest
      : message.ToolResponse;

    const markdown = message.Content.replace(
      /TOOLCALL:[\s\S]*?ENDARGUMENTS/,
      '',
    )
      .trim()
      .split('ENDTOOLCALL\nRESULT:')[0]
      .trim();

    tempMessage.collapsible = {
      type: tempMessage.toolType,
      html: JSON.stringify(toolData, null, 2),
    };

    if (
      message.Role === MESSAGE_ROLE.ASSISTANT ||
      message.ToolRequest ||
      message.ToolResponse
    ) {
      tempMessage.Role = MESSAGE_ROLE.LAB_ASSISTANT;
    }

    if (message.ToolRequest || message.ToolResponse) {
      tempMessage.Role = MESSAGE_ROLE.LAB_ASSISTANT;
    }
    tempMessage.html = marked.parse(markdown);
  } else {
    tempMessage.html = marked.parse(message.Content);
  }

  if (message.Role === MESSAGE_ROLE.ASSISTANT) {
    tempMessage.Role = MESSAGE_ROLE.LAB_ASSISTANT;
  }

  return tempMessage;
};

const sortNameAlphabetically = (data) => {
  return data.sort((a, b) => a.name.localeCompare(b.name));
};

const transformSpecificationConfigs = (specificationConfigs) => {
  return specificationConfigs?.map((specConfig) => ({
    range: { min: '', max: '' },
    config: specConfig._id,
    description: specConfig.description,
    testSubType: specConfig.subtype._id,
    testType: specConfig.subtype.testType,
    _id: specConfig._id,
  }));
};

const roundDecimals = (num: number | undefined): string | undefined => {
  if (!num) {
    return undefined;
  }

  const config = JSON.parse(localStorage.getItem('config') as any);
  const decimalDigits = config?.decimalPlaces || 3;
  return num.toFixed(decimalDigits);
};

export {
  transformDevices,
  transformRawDataUrl,
  transformHeadersForResultsTable,
  transformHeadersForMetadataTable,
  transformHeadersForManualInputsTable,
  transformDataForManualInputsTable,
  transformDataForChart,
  transformDataForChartWithConfig,
  dateTimeHelper,
  getAllHeaders,
  findPretreatmentNameForTest,
  transformColumnsForDataSearch,
  transformManualInputsForDataSearch,
  transformOverviewDates,
  transformChartDates,
  transformHeadersForChartTable,
  getUniqueElementsByField,
  getSignFromOperation,
  transformFormattedDataWithComputedColumns,
  filterNumericColumns,
  filterColumns,
  transformVisibleTableFields,
  transformRecipeVariantPercentageValue,
  transformChatMessage,
  sortNameAlphabetically,
  transformSpecificationConfigs,
  calculateRecipeVariantPercentage,
  roundDecimals,
};
